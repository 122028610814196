import $ from 'jquery';
import { SignalingClient, Role } from 'amazon-kinesis-video-streams-webrtc';
import axios from 'axios';

/**
 * This file demonstrates the process of starting WebRTC streaming using a KVS Signaling Channel.
 */
const master = {
    signalingClient: null,
    peerConnectionByClientId: {},
    dataChannelByClientId: {},
    localStream: null,
    remoteStreams: [],
    peerConnectionStatsInterval: null,
    kinesisVideoSignalingChannelsClient: null,
    pendingIceCandidateByClientId: {},
};

let channelARN = null;
let iceServers = [];
let endpointsByProtocol = [];


const formValues = {
    region: 'eu-central-1',
    accessKeyId: 'AKIAIPIDH7SB7L65VRIQ',
    secretAccessKey: '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',
    sessionToken: '',
    endpoint: 'kinesisvideo.eu-central-1.amazonaws.com',
    correctClockSkew: true,
    channelName: 'alttag_webcams_singaling',
    sendVideo: true,
    // sendAudio: true
};

export const startMaster = async (localView, remoteView, onStatsReport, onRemoteDataMessage) => {
    master.localView = localView;
    master.remoteView = remoteView;

    let url =  '/webcam-stream/start';
    if ($(localView).data('event')) {
        url = '/' + $(localView).data('event') + url;
    }
    const data = await axios.get(url).then(response => response.data);
    channelARN = data.channelARN;
    iceServers = data.iceServers;
    endpointsByProtocol = data.endpointsByProtocol;
    // kinesisVideoClient.config = data.kvsConfig;

    // Create KVS client
    // REMOVE
    // const kinesisVideoClient = new AWS.KinesisVideo({
    //     region: formValues.region,
    //     accessKeyId: formValues.accessKeyId,
    //     secretAccessKey: formValues.secretAccessKey,
    //     sessionToken: formValues.sessionToken,
    //     endpoint: formValues.endpoint,
    //     correctClockSkew: true,
    // });

    // Get signaling channel ARN
    // REMOVE
    // const describeSignalingChannelResponse = await kinesisVideoClient
    //     .describeSignalingChannel({
    //         ChannelName: formValues.channelName,
    //     })
    //     .promise();
    // const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    // console.log('[MASTER] Channel ARN: ', channelARN);

    // Get signaling channel endpoints
    // REMOVE
    // const getSignalingChannelEndpointResponse = await kinesisVideoClient
    //     .getSignalingChannelEndpoint({
    //         ChannelARN: channelARN,
    //         SingleMasterChannelEndpointConfiguration: {
    //             Protocols: ['WSS', 'HTTPS'],
    //             Role: Role.MASTER,
    //         },
    //     })
    //     .promise();

    // REMOVE
    // const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
    //     endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
    //     return endpoints;
    // }, {});
    // console.log('[MASTER] Endpoints: ', endpointsByProtocol);

    // Create Signaling Client
    // ??
    try {
        master.signalingClient = new SignalingClient({
            channelARN: channelARN,
            channelEndpoint: endpointsByProtocol['WSS'],
            role: Role.MASTER,
            region: formValues.region,
            credentials: {
                accessKeyId: formValues.accessKeyId,
                secretAccessKey: formValues.secretAccessKey,
                sessionToken: formValues.sessionToken,
            },
        });
    } catch (e) {
        console.log(e);
    }

    // Get ICE server configuration
    // REMOVE
    // const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
    //     region: formValues.region,
    //     accessKeyId: formValues.accessKeyId,
    //     secretAccessKey: formValues.secretAccessKey,
    //     sessionToken: formValues.sessionToken,
    //     endpoint: endpointsByProtocol.HTTPS,
    //     correctClockSkew: true,
    // });
    // const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
    //     .getIceServerConfig({
    //         ChannelARN: channelARN,
    //     })
    //     .promise();
    // REMOVE
    // const iceServers = [];
    // if (!formValues.natTraversalDisabled && !formValues.forceTURN) {
    //     iceServers.push({ urls: `stun:stun.kinesisvideo.${formValues.region}.amazonaws.com:443` });
    // }
    // REMOVE
    // if (!formValues.natTraversalDisabled) {
    //     getIceServerConfigResponse.IceServerList.forEach(iceServer =>
    //         iceServers.push({
    //             urls: iceServer.Uris,
    //             username: iceServer.Username,
    //             credential: iceServer.Password,
    //         }),
    //     );
    // }
    // console.log('[MASTER] ICE servers: ', iceServers);

    const configuration = {
        iceServers,
        iceTransportPolicy: 'all',
    };

    const resolution = formValues.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
        video: formValues.sendVideo ? resolution : false,
        audio: formValues.sendAudio,
    };

    // Get a stream from the webcam and display it in the local view.
    // If no video/audio needed, no need to request for the sources.
    // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
    if (formValues.sendVideo || formValues.sendAudio) {
        try {
            master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
            localView.srcObject = master.localStream;
            $(localView).removeClass('d-none');
        } catch (e) {
            console.error('[MASTER] Could not find webcam');
        }
    }

    master.signalingClient.on('open', async () => {
        console.log('[MASTER] Connected to signaling service');
    });

    master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
        console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);

        // Create a new peer connection using the offer from the given client
        const peerConnection = new RTCPeerConnection(configuration);
        master.peerConnectionByClientId[remoteClientId] = peerConnection;

        if (formValues.openDataChannel) {
            master.dataChannelByClientId[remoteClientId] = peerConnection.createDataChannel('kvsDataChannel');
            peerConnection.ondatachannel = event => {
                event.channel.onmessage = onRemoteDataMessage;
            };
        }

        // Poll for connection stats
        if (!master.peerConnectionStatsInterval) {
            master.peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(onStatsReport), 1000);
        }

        // Send any ICE candidates to the other peer
        peerConnection.addEventListener('icecandidate', ({ candidate }) => {
            if (candidate) {
                console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

                // When trickle ICE is enabled, send the ICE candidates as they are generated.
                console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
                master.signalingClient.sendIceCandidate(candidate, remoteClientId);

            } else {
                console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);

                // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
                // if (!formValues.useTrickleICE) {
                //     console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
                //     master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
                // }
            }
        });

        // As remote tracks are received, add them to the remote view
        peerConnection.addEventListener('track', event => {
            console.log('[MASTER] Received remote track from client: ' + remoteClientId);
            if (remoteView.srcObject) {
                return;
            }
            remoteView.srcObject = event.streams[0];
        });

        // If there's no video/audio, master.localStream will be null. So, we should skip adding the tracks from it.
        if (master.localStream) {
            master.localStream.getTracks().forEach(track => peerConnection.addTrack(track, master.localStream));
        }
        await peerConnection.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
        await peerConnection.setLocalDescription(
            await peerConnection.createAnswer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            }),
        );

        // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
        master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);

        console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
    });

    master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
        console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);

        // Add the ICE candidate received from the client to the peer connection
        const peerConnection = master.peerConnectionByClientId[remoteClientId];
        peerConnection.addIceCandidate(candidate);
    });

    master.signalingClient.on('close', () => {
        console.log('[MASTER] Disconnected from signaling channel');
    });

    master.signalingClient.on('error', () => {
        console.error('[MASTER] Signaling client error');
    });

    console.log('[MASTER] Starting master connection');
    master.signalingClient.open();
};

export const stopMaster = async (event) => {
    console.log('[MASTER] Stopping master connection');
    if (master.signalingClient) {
        master.signalingClient.close();
        master.signalingClient = null;
    }

    Object.keys(master.peerConnectionByClientId).forEach(clientId => {
        master.peerConnectionByClientId[clientId].close();
    });
    master.peerConnectionByClientId = [];

    if (master.localStream) {
        master.localStream.getTracks().forEach(track => track.stop());
        master.localStream = null;
    }

    master.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
    master.remoteStreams = [];

    if (master.peerConnectionStatsInterval) {
        clearInterval(master.peerConnectionStatsInterval);
        master.peerConnectionStatsInterval = null;
    }

    if (master.localView) {
        master.localView.srcObject = null;
        $(master.localView).addClass('d-none');
    }

    if (master.remoteView) {
        master.remoteView.srcObject = null;
    }

    if (master.dataChannelByClientId) {
        master.dataChannelByClientId = {};
    }

    let url =  '/webcam-stream/stop';
    if (event) {
        url = '/' + event + '/' + url;
    }
    let data = null;
    // if (navigator.sendBeacon) {
    //     data = navigator.sendBeacon(url);
    // } else {
        data = await axios.get(url).then(response => response.data);
    // }
};
