const ACTION_LOAD = 'load';
const ACTION_LEAVE = 'leave';
const ACTION_PLAY = 'play';
const ACTION_PAUSE = 'pause';

const video_iframe = $("iframe[src*='vimeo.com']")
for (video of video_iframe) {
    let video_player = new Vimeo.Player(video);
    video_player.on('playing', function () {
        var fd = new FormData();
        fd.append('action', ACTION_PLAY);
        fd.append('pathname', document.location.pathname);
        navigator.sendBeacon('/beacon/stats', fd);
    });
    video_player.on('pause', function () {
        var fd = new FormData();
        fd.append('action', ACTION_PAUSE);
        fd.append('pathname', document.location.pathname);
        navigator.sendBeacon('/beacon/stats', fd);
    });
}

$(window).on('load', function () {
    var fd = new FormData();
    fd.append('action', ACTION_LOAD);
    fd.append('pathname', document.location.pathname);
    navigator.sendBeacon('/beacon/stats', fd);
})

$(window).on('beforeunload', function () {
    var fd = new FormData();
    fd.append('action', ACTION_LEAVE);
    fd.append('pathname', document.location.pathname);
    navigator.sendBeacon('/beacon/stats', fd);
});
