import $ from "jquery";
import "jquery-ui/ui/widgets/sortable";
import "bootstrap";
import "bootstrap-select";
import "./bootstrap";
import "./stream-connection";
import "./stream";
import "./statistics";

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

$(".bootstrap-select").selectpicker();
window.$ = window.jQuery = $;

window.redrawSnippet = function (id = null, content = "") {
    var element = $("#" + id);
    if (element.length && content) {
        element.html(content);
    }
};

$("[data-toggle=tooltip]").tooltip();

// const video = $('.user-video__video');
// const button = $('.user-video__controls button');
// $(function() {
//     button.on('click', function(e) {
//         console.log('click', video.hasClass('d-none'));
//         if (video.hasClass('d-none')) {
//             video.removeClass('d-none');
//             button.text('Opustiť publikum');
//         } else {
//             video.addClass('d-none');
//             button.text('Pridať sa do publika');
//         }
//     })
// })

/**
 * Tabs repeater fields
 */
$(document).ready(function () {
    var repeaterContainer = $(".js-repeater-fields-container");

    if (repeaterContainer.length > 0) {
        repeaterContainer.sortable({
            update: function () {
                console.log("drop");

                updateRepeaterLoopIndexes();
            },
        });

        $(document).on("click", ".js-add-repeater-field", function (e) {
            e.preventDefault();

            var fields = $(".js-repeater-fields-template > div").clone();

            fields.removeAttr("style");
            fields.find("input").val("");
            fields.find("select").val("");

            repeaterContainer.append(fields);

            updateRepeaterLoopIndexes();
        });

        $(document).on("click", ".js-remove-repeater-field", function () {
            $(this).parent().remove();

            updateRepeaterLoopIndexes();
        });
    }

    function updateRepeaterLoopIndexes() {
        repeaterContainer.find("select").each(function (index) {
            $(this).attr("name", "stages_ids[" + index + "][]");
        });
    }
});
